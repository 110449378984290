<template>

    <section id="welcome" style="height: 100vh" class="d-lg-flex align-items-center justify-content-center">
      <div className="d-flex w-100 flex-column flex-lg-row justify-content-center align-items-center">
        <div className="d-flex flex-column p-5">
          <div className="d-flex flex-column mr-2" style="gap: 40px;">
            <div>
              <h1 className="gradient-header">Mining Center</h1>
              <h5>Поставщик оборудования для майнинга №1 в РФ</h5>
            </div>
            <div className="mt-8">У нас крупнейшее сообщество в СНГ в тематике майнинг оборудования - около 20 тысяч реальных участников. <br>
              Мы являемся проверенным и надежным поставщиком с более чем 1000 подтвержденных отзывов.<br>
              Ежедневные актуальные цены. Непрерывное обсуждение, помощь в выборе асиков и другого оборудования для майнинга.<br>
            </div>
            <div>
              Вступай в наше сообщество, чтобы подобрать идеальный момент для покупки оборудования.
            </div>
            <div>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <a href="https://t.me/+shnLHxeKrwM4ODNi" target="_blank">
                  <button type="submit" className="btn btn-outline-warning mt-8 btn-land">
                    Вступить в Telegram чат
                  </button>
                </a>
              <a href="#" style="opacity: 0">
                <button type="submit" className="btn btn-outline-warning mt-8 btn-land">
                  Вступить в Telegram чат
                </button>
              </a>
                <a href="#" style="visibility: hidden">
                  <button type="submit" className="btn btn-outline-warning mt-8 btn-land">
                    Вступить в Telegram чат
                  </button>
                </a>
            </div>
            </div>
          </div>
        </div>
        <div className="d-flex">
          <div className="d-flex p-lg-5 justify-content-center preview"
               style="border-radius: 20px; margin-bottom: -5px">
            <img
                 class="preview"
                 src='./img/22.png'
                 alt="Предпосмотр приложения"
                 title="Предпосмотр приложения">
          </div>
        </div>
      </div>
    </section>


</template>

<script>

export default {
 created() {
   document.title = "Mining center - Ваш надежный поставщик майнинг оборудования из Китая";
 }
};
</script>

<style lang="scss">

body {
  overflow-x: hidden;
  width: 100%;
  margin: 0;
}

.gradient-header {
  background: linear-gradient(80deg, #00d5d5 0, #8950fc);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.yellow {
  color: #d5b600;
}

.purple-light {
  color: #b088ff;
}

</style>
